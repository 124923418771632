<template>
    <div class="menu__inner">
		<nav class="menu__nav">
			<ul class="menu__nav-links" id="menuList">
				<li @click="clickMenu(4)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][4].title}}</a>
				</li>
                <li @click="clickMenu(5)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][5].title}}</a>
				</li>
                <li @click="clickMenu(6)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][6].title}}</a>
				</li>
                <li @click="clickMenu(3)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][3].title}}</a>
				</li>
                <li @click="clickMenu(2)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][2].title}}</a>
				</li>
                <li @click="clickMenu(1)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][1].title}}</a>
				</li>
                <li @click="clickMenu(7)" class="menu__nav-item" ref="menuItems" data-csstween="1" style="pointer-events: all;">
					<a class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{text[setLang][7].title}}</a>
				</li>
			</ul>
		</nav>
        <ul class="menu__nav-links" id="asideMenu">
            <li @click="clickMenu(8)" class="item-back" data-csstween="1" style="pointer-events: all;">
                <span><img class="item-back-img" src="@/assets/main-menu.png" alt=""></span><a id="menuIcon" class="menu__nav-link" href="#" rel="internal" ref="programMenuLink">{{menuText[setLang].menu}}</a>
            </li>
        </ul>
	</div>
</template>

<script>
import lang from '@/lang/language';

export default {
    setup() {
        return {
            text: lang.mainModal,
            menuText: lang.mainMenu
        }
    },
    computed: {
        setLang() {
            return this.$store.state.user.lang;
        }
    },
    methods: {
        clickMenu(selected) {
            if(selected === 8) {
                this.inActive();
                this.$emit("clickMenuFromChild", selected);
                this.$emit("zoomOutCamera");
                return;
            }
            this.$emit("clickMenuFromChild", selected);

            // this.$emit("zoomOutCamera");
            
            this.active();
        },
        active() {
            const animateList = document.getElementById("menuList");
            animateList.style.transform = "translateX(-2000px)";
            
            const menu = document.getElementById("asideMenu");
            menu.style.transform = "translate(0px, -50%)";
        },
        inActive() {
            const menu = document.getElementById("asideMenu");
            menu.style.transform = "translate(-2000px, -50%)";

            const animateList = document.getElementById("menuList");
            animateList.style.transform = "none";
        }
    }
}
</script>

<style scoped>
ul {
    padding-left: 0rem;
    margin-bottom: 0px;
}
a {
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 30px;
}
.menu__inner {
    padding-left: 4.58em;
    border-left: 1px solid rgba(255,255,255,.2);
    width: inherit;
    margin-left: 3rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* overflow: hidden; */
}
li::marker {
    color: #ed4343;
}
.menu__nav-links {
    cursor: pointer;
    transition: all 300ms ease-in-out;
}
.menu__nav-item {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}
.menu__nav-item:hover {
    transform: translate3d(0.9em,0,0);
}
li::after{
    padding-left:5px;
}
li {
    text-align: start;
    list-style: none;
}
.menu__nav-item:hover::before {
    background-color: #ed4343;
    transform: scale3d(1,1,1);
}
.menu__nav-item::before {
    position: absolute;
    top: 50%;
    left: -5.49em;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.82em;
    margin-left: -0.62em;
    background-color: #fff;
    border-radius: 8.33em;
    transform: scale3d(0,0,0);
    content: "";
}
.menu__nav-item::before, .menu__nav-link {
    transition: .2s cubic-bezier(.55,0,.1,1);
    transition-property: transform,background-color;
}
#asideMenu {
    position: absolute;
    top: 50%;
    transform: translate(-2000px, -50%);
}

#menuIcon:hover {
    color: #ed4343;
}
.item-back:hover::before {
    transform: scale3d(2,2,2);
}
.item-back::before {
    position: absolute;
    top: 50%;
    left: -4.58em;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.62em;
    margin-left: -0.62em;
    border-radius: 8.33em;
    /* content: "☰"; */
    content: "";
    color: white;
    transform: scale3d(2,2,2);
}
.item-back-img {
    position: absolute;
    top: 50%;
    left: -4.58em;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.62em;
    margin-left: -0.62em;
    border-radius: 8.33em;
    content: "";
    transform: scale3d(3,3,3);
}
.item-back::before, .menu__nav-link {
    transition: .2s cubic-bezier(.55,0,.1,1);
    transition-property: transform,background-color;
}
@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    a {
        font-size: 30px;
    }
    .menu__inner {
        padding-left: 1em;
        margin-left: 1rem;
    }
    .item-back-img {
        left: -1em;
        width: 20px;
        transform: scale3d(1,1,1);
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    a {
        font-size: 20px;
    }
    .menu__inner {
        padding-left: 1em;
        margin-left: 1rem;
    }
    .item-back-img {
        left: -1em;
        width: 20px;
        transform: scale3d(1,1,1);
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    a {
        font-size: 15px;
    }
    .menu__inner {
        padding-left: 1em;
        margin-left: 1rem;
    }
    .item-back-img {
        left: -1em;
        width: 20px;
        transform: scale3d(1,1,1);
    }
}

@media (max-width: 481px) {
    a {
        font-size: 20px;
    }
    .menu__inner {
        padding-left: 1em;
        margin-left: 1rem;
    }
    .item-back-img {
        left: -1em;
        width: 20px;
        transform: scale3d(1,1,1);
    }
}
</style>