<template>
    <div id="introBackGround">
        <video oncontextmenu="return false;" id="myVideo" autoplay muted loop playsinline>
            <source src="https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/intro2.mp4" type="video/mp4">
        </video>
        <div class="title-container">
            <div class="box">
                <h1 class="title">{{text[setLang].title}}</h1>
            </div>
            <div v-if="!trigger" class="box d-flex align-items-center" @click="()=>goToScene()">
                <img src="@/assets/enter-btn.png" alt="...">
                <h2 class="enter-text">{{enter[setLang]}}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import lang from '@/lang/language';
import { toRefs } from 'vue';

export default {
    props: [
        "readyTrigger"
    ],
    setup(props) {
        const {readyTrigger} = toRefs(props);
        
        return {
            text: lang.landing,
            enter: lang.introEnter,
            trigger : readyTrigger
        }
    },
    computed: {
        setLang() {
            return this.$store.state.user.lang;
        }
    },
    methods: {
        goToScene() {
            const wrapper = document.getElementById('introBackGround');
            if (wrapper) {
                wrapper.remove();
            }

            this.$store.dispatch("setSound", {
                sound: true
            });
        },
    }
}
</script>

<style scoped>
.title-container {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    min-height: 15rem;
    color: white;
}
.box {
    margin-bottom: 1.5rem;
    cursor: pointer;
}
.title {
    font-weight: bolder;
    font-size: 65px;
}
.box > img {
    width: 80px;
}
.enter-text {
    margin-left: 1rem;
}
#introBackGround{
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    display: block;
    /* object-fit: cover; */
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    z-index: 3;
    background-color: black;
}
#myVideo {
    width: 100vw;
    height: 100vh;
    object-fit: cover
}

@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .title-container {
        min-height: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .title-container {
        min-height: 0px;
    }
    .title {
        font-size: 60px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .title-container {
        min-height: 0px;
    }
    .box > img {
        cursor: pointer;
        width: 60px;
    }
    .title {
        font-size: 50px;
    }
}

@media (max-width: 481px) {
    .title-container {
        min-height: 0px;
    }
    .box > img {
        cursor: pointer;
        width: 60px;
    }
    .title {
        font-size: 30px;
    }
}
</style>