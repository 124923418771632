<template>
    <Transition name="slide-fade">
        <MainModal v-if="isModalOpen" :index="contentIndex" @closeFromAlertChild="closeModal" @zoomOutCamera="zoomOutCamera" />
    </Transition>
    <canvas id="jejuCanvas" ref="jejuCanvas"></canvas>
    <MainMenu ref="mainMenu" @clickMenuFromChild="selectedContent" @zoomOutCamera="zoomOutCamera" />
    <Transition>
        <LandingView :readyTrigger="readyToSceneTrigger" />
    </Transition>
</template>

<script>
import MainModal from "@/components/modal/MainModal.vue";
import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import "@babylonjs/loaders";
import { onMounted, ref, onUnmounted } from "@vue/runtime-core";
import SceneManager from '@/sceneManager/sceneManager';
import LandingView from '@/components/intro/LandingView';
import MainMenu from "@/components/menu/MainMenu.vue";

export default {
    components: {
        MainModal,
        LandingView,
        MainMenu
    },
    setup() {
        let sceneManager;
        const mainMenu = ref(null);
        const isModalOpen = ref(false);
        const contentIndex = ref(1);
        const jejuCanvas = ref(null);
        const readyToSceneTrigger = ref(true);
        const symbolMeshes = {
            "basket" : [],
            "tree" : [],
            "boat" : [],
            "well" : [],
            "water" : [],
            "flower" : [],
            "lobby" : [],
        };

        onMounted( async ()=>{
            sceneManager = SceneManager.getInstance();
            sceneManager.setEngine(new BABYLON.Engine(jejuCanvas.value));
            sceneManager.setScene(new BABYLON.Scene(sceneManager.engine));

            const hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("https://kfmetaverse.blob.core.windows.net/static/assets/environment.env", sceneManager.scene);
            hdrTexture.level = 2;
            sceneManager.scene.environmentTexture = hdrTexture;

            //createCamera
            createCamera(sceneManager.scene);

            //createLight
            const [shadowGenerator, shadowGenerator2] = await createLight(sceneManager.scene);

            //createBackGround
            await createBackground(sceneManager.scene, shadowGenerator, shadowGenerator2);

            createMeshAnimation(sceneManager.scene);

            sceneManager.scene.animationPropertiesOverride = new BABYLON.AnimationPropertiesOverride();
            sceneManager.scene.animationPropertiesOverride.enableBlending = true;
            sceneManager.scene.animationPropertiesOverride.blendingSpeed = 0.01;
            sceneManager.scene.animationPropertiesOverride.loopMode = 1;


            sceneManager.engine.runRenderLoop(()=>{
                sceneManager.scene.render();
            });

            sceneManager.scene.executeWhenReady(()=>{
                readyToSceneTrigger.value = false;
            });

            window.addEventListener("resize", () => {
                if (jejuCanvas.value) {
                    jejuCanvas.value.width = window.innerWidth;
                    jejuCanvas.value.height = window.innerHeight;
                }
            });
        });

        const createSkyBox = async (scene) => {
            const SkyBoxMaterial = new BABYLON.StandardMaterial("skyBoxMaterial", scene);
            SkyBoxMaterial.backFaceCulling = false;
            SkyBoxMaterial.diffuseTexture = new BABYLON.Texture("https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/skyBox3.jpg", scene);
            SkyBoxMaterial.diffuseTexture.level = 1;
            SkyBoxMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
            SkyBoxMaterial.specularColor = new BABYLON.Color3(0,0,0);
            SkyBoxMaterial.freeze();
            const skyBox = BABYLON.MeshBuilder.CreateSphere("skyBox", {diameter:400}, scene);
            skyBox.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(180),0,0);
            skyBox.material = SkyBoxMaterial;

            const skyBox2 = BABYLON.MeshBuilder.CreateSphere("sphere", { diameter:399, slice: 0.5, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, scene);
            skyBox2.material = SkyBoxMaterial;
            skyBox2.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(180),0,0);

            return [skyBox, skyBox2]
        };

        const createSideBackGroundModel = async (scene) => {
            let dolphin;
            let dolphin2;
            let boat;
            let boat2;
            let buoy;
           
            await BABYLON.SceneLoader.ImportMeshAsync(null, "https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/", "jeju_dolphin.glb", scene).then(function(result) {
                const scale = 0.2;
                const mesh = result.meshes[0];
                dolphin = result.meshes;
                mesh.scaling = new BABYLON.Vector3(-scale, -scale, -scale);
                mesh.position = new BABYLON.Vector3(8, 0, 0);
                mesh.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(180), 0, 0);
                dolphin2 = mesh.clone();
                dolphin2.position = new BABYLON.Vector3(7.2, 0, 0);
            })

            await BABYLON.SceneLoader.ImportMeshAsync(null, "https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/", "buoy.glb", scene).then(function(result) {
                const scale = 0.2;
                const mesh = result.meshes[0];  
                buoy = result.meshes;
                mesh.scaling = new BABYLON.Vector3(scale, scale,  scale);
                mesh.position = new BABYLON.Vector3(10, -0.2, 8);
            })

            await BABYLON.SceneLoader.ImportMeshAsync(null, "https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/", "boat.glb", scene).then(function(result) {
                const scale = 0.15;
                const mesh = result.meshes[0];  
                boat = result.meshes;
                mesh.scaling = new BABYLON.Vector3(scale, scale,  scale);
                mesh.position = new BABYLON.Vector3(13, 0, -4);
            })

            await BABYLON.SceneLoader.ImportMeshAsync(null, "https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/", "boat2.glb", scene).then(function(result) {
                const scale = 0.6;
                const mesh = result.meshes[0]; 
                result.animationGroups[0].stop(); 
                boat2 = result.meshes;
                mesh.scaling = new BABYLON.Vector3(scale, scale,  scale);
                mesh.position = new BABYLON.Vector3(-10, 0, 2);
                mesh.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(16.5), 0);
            })

            return [dolphin, dolphin2, boat, boat2, buoy]
        };

        const createLight = async (scene) => {
            const light = new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, 0, 0), scene);
            light.intensity = 0.6;

            const dirLight = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(0.3,-1,0.2), scene);
            dirLight.position = new BABYLON.Vector3(-15,1,-10);
            dirLight.intensity = 0.9;
            dirLight.autoUpdateExtends = false

            const dirLight2 = new BABYLON.DirectionalLight("DirectionalLight2", new BABYLON.Vector3(0.3,-1,0.2), scene);
            dirLight2.position = new BABYLON.Vector3(-2,1,-10);
            dirLight2.intensity = 0.6;
            dirLight2.autoUpdateExtends = false

            const shadowGenerator = new BABYLON.ShadowGenerator(1024, dirLight);
            shadowGenerator.filter = "none"
            shadowGenerator._darkness = -1;

            const shadowGenerator2 = new BABYLON.ShadowGenerator(1024, dirLight2);
            shadowGenerator2.filter = "none"
            shadowGenerator2._darkness = 0;

            // scene.debugLayer.show({ embedMode: true }).then(() => {
            //     scene.debugLayer.select(light);
            // });

            return [shadowGenerator, shadowGenerator2];
        };

        const createOcean = async (scene, boat, boat2, buoy) => {
            const water = BABYLON.Mesh.CreateGround("water", 600, 600, 640, scene);
            water.position.y = -0.14;
            water.receiveShadows = true;

            BABYLON.Effect.ShadersStore["customVertexShader"]= "\r\n"+   
                "precision highp float;\r\n"+

                // Attributes
                "attribute vec3 position;\r\n"+
                "attribute vec2 uv;\r\n"+

                // Uniforms
                "uniform mat4 worldViewProjection;\r\n"+
                "uniform float time;\r\n"+
                "out float newY;\r\n"+

                // Varying
                "varying vec3 vPosition;\r\n"+
                "varying vec4 vClipSpace;\r\n"+

                "void main(void) {\r\n"+
                    "float scale = 1.0;\r\n"+
                    // calc new position
                    "float newY = (sin(position.z * 1.0 / scale + time * 1.0)) * .1 ;\r\n"+
                    // new model position
                    "vec3 newPositionM = vec3(position.x,newY,position.z);\r\n"+
                    "gl_Position = worldViewProjection * vec4(newPositionM, 1.0);\r\n"+
                    // grab vertex position in world space
                    "vPosition = position;\r\n"+
                    // grab vertex position in view space
                    "vClipSpace = gl_Position;\r\n"+
                "}\r\n";

            BABYLON.Effect.ShadersStore["customFragmentShader"]="\r\n"+
                "precision highp float;\r\n"+

                // Varyings
                "varying vec3 vPosition;\r\n"+
                // world distance, camera to water
                "varying vec4 vClipSpace;\r\n"+

                // Uniforms
                "uniform sampler2D depthTex;\r\n"+
                "uniform sampler2D refractionSampler;\r\n"+
                "uniform float camMinZ;\r\n"+
                "uniform float camMaxZ;\r\n"+
                "uniform float maxDepth;\r\n"+
                // water colors
                "uniform vec4 wFoamColor;\r\n"+
                "uniform vec4 wDeepColor;\r\n"+
                "uniform vec4 wShallowColor;\r\n"+
                "uniform float time;\r\n"+
                "uniform float wNoiseScale;\r\n"+
                "uniform float wNoiseOffset;\r\n"+
                "uniform float fNoiseScale;\r\n"+
                "in float newY;\r\n"+

                "float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}\r\n"+
                "vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}\r\n"+
                "vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}\r\n"+

                "float noise(vec3 p){\r\n"+
                    "vec3 a = floor(p);\r\n"+
                    "vec3 d = p - a;\r\n"+
                    "d = d * d * (3.0 - 2.0 * d);\r\n"+

                    "vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);\r\n"+
                    "vec4 k1 = perm(b.xyxy);\r\n"+
                    "vec4 k2 = perm(k1.xyxy + b.zzww);\r\n"+

                    "vec4 c = k2 + a.zzzz;\r\n"+
                    "vec4 k3 = perm(c);\r\n"+
                    "vec4 k4 = perm(c + 1.0);\r\n"+

                    "vec4 o1 = fract(k3 * (1.0 / 41.0));\r\n"+
                    "vec4 o2 = fract(k4 * (1.0 / 41.0));\r\n"+

                    "vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);\r\n"+
                    "vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);\r\n"+

                    "return o4.y * d.y + o4.x * (1.0 - d.y);\r\n"+
                "}\r\n"+

                "void main(void) {\r\n"+
                    // waveNoise
                    "float waveNoise = noise(vec3(0., time, 0.)+vPosition*wNoiseScale)*wNoiseOffset;\r\n"+
                    
                    // remap frag screen space coords to ndc (-1 to +1)
                    "vec2 ndc = (vClipSpace.xy / vClipSpace.w) / 2.0 + 0.5;\r\n"+
                    "float depthOfObjectBehindWater = texture2D(depthTex, vec2(ndc.x, ndc.y)+waveNoise).r;\r\n"+
                    "float linearWaterDepth = (vClipSpace.z + camMinZ) / (camMaxZ + camMinZ);\r\n"+
                    "float waterDepth = camMaxZ*(depthOfObjectBehindWater - linearWaterDepth);\r\n"+
                    "float wdepth = clamp((waterDepth/maxDepth), 0.0, 1.0);\r\n"+

                    // sampling refractive colors
                    "vec4 refractiveColor = texture2D(refractionSampler, vec2(ndc.x, ndc.y)+waveNoise+newY);\r\n"+
                    
                    // foam 
                    "float foam = 1.0-smoothstep(0.1, 0.2, wdepth);\r\n"+
                    "float foamEffect = smoothstep( 0.1, 0.2, noise(vec3(0., time, 0.)+vPosition*fNoiseScale*0.3)*foam);\r\n"+
                    "vec4 foamColor = vec4(foamEffect) *.5;\r\n"+

                    // Colors
                    "vec4 baseColor = vec4(0.0);\r\n"+
                    "baseColor = mix(wShallowColor, wDeepColor, wdepth);\r\n"+
                    "baseColor = mix(refractiveColor, baseColor, baseColor.a);\r\n"+
                    "baseColor = mix(wFoamColor, baseColor, 1. - foamColor.r);\r\n"+
                    // final result
                    "gl_FragColor = baseColor;\r\n"+
                    
                "}\r\n";

            const shaderMaterial = new BABYLON.ShaderMaterial("shader", scene, {vertex: "custom",fragment: "custom"},
                {
                    attributes: ["position", "normal", "uv"],
                    uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
                });

            const depthRenderer = scene.enableDepthRenderer(scene.activeCamera, false);
            const depthTex = depthRenderer.getDepthMap();

            const _refractionRTT = new BABYLON.RenderTargetTexture("water_refraction", { width: 256, height: 256 }, scene, false, true);
            _refractionRTT.wrapU = BABYLON.Constants.TEXTURE_MIRROR_ADDRESSMODE;
            _refractionRTT.wrapV = BABYLON.Constants.TEXTURE_MIRROR_ADDRESSMODE;
            _refractionRTT.ignoreCameraViewport = true;
            _refractionRTT.refreshRate = 1;

            shaderMaterial.setTexture("depthTex", depthTex);
            shaderMaterial.setTexture("refractionSampler", _refractionRTT);
            shaderMaterial.setFloat("camMinZ", scene.activeCamera.minZ);
            shaderMaterial.setFloat("camMaxZ", scene.activeCamera.maxZ);
            shaderMaterial.setFloat("time", 0);
            shaderMaterial.setFloat("wNoiseScale", 6.0);
            shaderMaterial.setFloat("wNoiseOffset", 0.01);
            shaderMaterial.setFloat("fNoiseScale", 10.0);
            shaderMaterial.setFloat("maxDepth", 5.0);
            shaderMaterial.setVector4("wDeepColor", new BABYLON.Vector4(70/255,141/255,203/255, 0.8));
            // shaderMaterial.setVector4("wDeepColor", new BABYLON.Vector4(0.0, 0.3, 0.5, 0.8));
            shaderMaterial.setVector4("wShallowColor", new BABYLON.Vector4(0.0, 0.6, 0.8, 0.8));
            shaderMaterial.setVector4("wFoamColor", new BABYLON.Vector4(1,1,1,1));
            shaderMaterial.alpha = .7;

            water.material = shaderMaterial;
            var time = 0;
            scene.registerBeforeRender(function() {
                time += sceneManager.engine.getDeltaTime() * 0.002;
                shaderMaterial.setFloat("time", time);

                const heelAmount = Math.sin(Date.now() * 0.002) * 0.1;
                buoy[0].rotation = new BABYLON.Vector3(0,0,heelAmount);
                boat[0].rotation = new BABYLON.Vector3(0,0,heelAmount);
                boat2[1].rotation = new BABYLON.Vector3(0,0,heelAmount);
            });

            return [_refractionRTT, depthTex]
        };

        const createBackground = async (scene, shadowGenerator, shadowGenerator2) => {
            const meshResult = await BABYLON.SceneLoader.ImportMeshAsync("", "https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/", `Jeju3.glb`, scene);

            const [dolphin, dolphin2, boat, boat2, buoy] = await createSideBackGroundModel(scene);

            const [_refractionRTT, depthTex] = await createOcean(scene, boat, boat2, buoy);

            const [skyBox, skyBox2] = await createSkyBox(scene);

            async function setMesh(newMeshes, animationGroups){
                const lobby = newMeshes[0];
                lobby.scaling = new BABYLON.Vector3(-50,-50,-50);
                lobby.position = new BABYLON.Vector3(0,0,0);
                lobby.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(0), BABYLON.Tools.ToRadians(180), BABYLON.Tools.ToRadians(180));
                const mountain = scene.getMeshByName('jeju_island');

                _refractionRTT.renderList = depthTex.renderList = [mountain, skyBox, skyBox2, boat2[1], dolphin[1], dolphin2.getChildMeshes()[1], boat[16], buoy[1]];
            
                scene.customRenderTargets.push(_refractionRTT);

                for (let j=0; j<newMeshes.length; j++) {
                    newMeshes[j].checkCollisions = true;
                    newMeshes[j].receiveShadows = true;
                    shadowGenerator.addShadowCaster(newMeshes[j], true);
                    shadowGenerator2.addShadowCaster(newMeshes[j], true);

                    if (newMeshes[j].name == '5.The lost') {
                        symbolMeshes.well.push(newMeshes[j]);
                    } else if (newMeshes[j].name == '6.BSM') {
                        symbolMeshes.flower.push(newMeshes[j]);
                    } else if (newMeshes[j].name == '2.Nakseon-dong') {
                        symbolMeshes.tree.push(newMeshes[j]);
                    } else if (newMeshes[j].name == '1.Bukchon Neobunsoongi') {
                        symbolMeshes.boat.push(newMeshes[j]);
                    } else if (newMeshes[j].name == '3.Darangshi cave') {
                        symbolMeshes.basket.push(newMeshes[j]);
                    } else if (newMeshes[j].name == '4.Former distillery') { 
                        symbolMeshes.water.push(newMeshes[j]);
                    } else if (newMeshes[j].name == 'memorial_park2_primitive0' || newMeshes[j].name == 'memorial_park2_primitive1' || newMeshes[j].name == 'memorial_park2_primitive2') {
                        symbolMeshes.lobby.push(newMeshes[j]);
                    } else if (newMeshes[j].name == 'baengnokdam_lake') {
                        newMeshes[j].material.specularColor = new BABYLON.Color3(0, 0, 0);
                        newMeshes[j].material.alpha = 1;
                    }
                }

                for (let i=0; i<animationGroups.length; i++) {
                    animationGroups[i].start(true);
                }
            }
            await setMesh(meshResult.meshes, meshResult.animationGroups);
        };

        const zoomOutCamera = () => {

            const cam = sceneManager.scene.activeCamera;

            const targetEndPos = new BABYLON.Vector3(3., 0, 0);
            const cameraEndPos = new BABYLON.Vector3(1.3170189605500446,19.919504175811078, 9.185909220686709);

            sceneManager.scene.stopAnimation(cam);
            cam.useAutoRotationBehavior = false;
            cam.attachControl(jejuCanvas.value, true);

            const ease = new BABYLON.CubicEase();
            ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

            const animation = new BABYLON.Animation(
                'cameraAnimation',
                'position',
                50,
                BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
            );
            const keys = [];
            keys.push({
                frame: 0,
                value: cam.position.clone()
            });
            keys.push({
                frame: 100,
                value: cameraEndPos
            });

            animation.setKeys(keys);
            animation.setEasingFunction(ease);
            sceneManager.scene.activeCamera.animations.push(animation);
            sceneManager.scene.beginAnimation(sceneManager.scene.activeCamera, 0, 100, false);
            
            cam.lockedTarget = targetEndPos;
        };

        const createGUI = (scene, mesh, key, text, width, boxPosition, size, index) => {
            const box = new BABYLON.MeshBuilder.CreateBox(text, {size:2}, scene);
            box.position = boxPosition;
            if (size != null && key =='boat') {
                box.scaling = size;
                box.rotation =  new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(77),0);
                box.position =  new BABYLON.Vector3(-5, 0.1, -7);
            } else if ( key == 'basket') {
                box.rotation =  new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(165.3000),0);
                box.scaling = size;
            } else if ( key == 'tree') {
                box.rotation =  new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(166.8),0);
                box.scaling = size;
            } else if ( key == 'well') {
                box.rotation =  new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(164.4),0);
                box.scaling = size;
            } else if (key == 'water') {
                box.rotation =  new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(166.2),0);
                box.scaling = size;
            } else if (key == 'flower') {
                box.rotation =  new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(165.9),0);
                box.scaling = size;
            } else if (size != null) {
                box.scaling = size;
            }
            box.material = new BABYLON.StandardMaterial(text+'mat', scene);
            box.material.alpha = 0;
            box.actionManager = new BABYLON.ActionManager(scene);

            if (key == 'lobby') {
                for (let i=0; i<symbolMeshes.lobby.length; i++) {
                    const mat = symbolMeshes.lobby[i].material.emissiveColor;
                    box.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function(){
                        symbolMeshes.lobby[i].material.emissiveColor = new BABYLON.Color3(0.109,0.129,0.0);
                    }));
                    
                    box.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function(){
                        symbolMeshes.lobby[i].material.emissiveColor = mat;
                    }));
                }
                box.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
                    if (!isModalOpen.value) {
                        isModalOpen.value = true;
                        contentIndex.value = index;
                        zoom(scene.activeCamera, box, key);
                    }
                }));
                return
            }

            box.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
                if (!isModalOpen.value) {
                    isModalOpen.value = true;
                    contentIndex.value = index;
                    zoom(scene.activeCamera, box, key);
                }
            }));
        };

        const zoom = function (cam, tar, key) {
            sceneManager.scene.stopAnimation(sceneManager.scene.activeCamera);
            mainMenu.value.active();
            const box = sceneManager.scene.getMeshByName('target');
            if (box) {
                box.position = cam.position;
            } else {
                const box = new BABYLON.MeshBuilder.CreateBox('target', {size:2}, sceneManager.scene);
                box.position = cam.position;
                box.isVisible = false;
            }
            let targetEndPos = tar._absolutePosition;
            let global_position;
            const speed = 45;
            const ease = new BABYLON.CubicEase();

            tar.computeWorldMatrix();
            const matrix = tar.getWorldMatrix(true);
            const local_position = new BABYLON.Vector3(0,0,0);
            if (key == 'basket') {
                global_position = new BABYLON.Vector3(-9.99419168278957, 9.123302924086575, -1.65026554058608);
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x -1.2,tar._absolutePosition.y,tar._absolutePosition.z);
            } else if (key == 'tree') {
                global_position = new BABYLON.Vector3(-5.321834799847803, 9.330690352294148, -5.5134002245171425);
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x -0.5,tar._absolutePosition.y,tar._absolutePosition.z);
            } else if (key == 'boat') {
                global_position = new BABYLON.Vector3(-6.041301114164677, 9.329479668877715, -5.306840505400506);
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x -0.5, tar._absolutePosition.y,tar._absolutePosition.z - 0.5);
            } else if (key == 'well') {
                global_position = new BABYLON.Vector3(-2.266639197032613, 8.796385055923647, -4.953175214099293);
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x - 0.5,tar._absolutePosition.y,tar._absolutePosition.z - 0.5);
            } else if (key == 'water') {
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x - 0.6,tar._absolutePosition.y,tar._absolutePosition.z);
                global_position = new BABYLON.Vector3(0.9648274846275051, 7.974692803526448, -4.41916344092149);
            } else if (key == 'flower') {
                local_position.addInPlace(new BABYLON.Vector3(-0.9, 8, 4));
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x - 1,tar._absolutePosition.y,tar._absolutePosition.z);
                global_position = new BABYLON.Vector3(6.387819115963685, 5.048122832302587, 4.182475473966893);
            } else if (key == 'lobby') {
                local_position.addInPlace(new BABYLON.Vector3(0, 0, 7));
                targetEndPos = new BABYLON.Vector3(tar._absolutePosition.x - 1,tar._absolutePosition.y,tar._absolutePosition.z);
                global_position = BABYLON.Vector3.TransformCoordinates(local_position, matrix);
            }
            ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
            BABYLON.Animation.CreateAndStartAnimation('at4', cam, 'position', speed, 120, cam.position, global_position, 0, ease, ()=>{
                cam.useAutoRotationBehavior = true;
            });
            cam.detachControl();
            BABYLON.Animation.CreateAndStartAnimation('at5', cam, 'target', speed, 120, cam.target, targetEndPos, 0, ease);
            cam.lockedTarget = targetEndPos;
        };

        const createMeshAnimation = (scene) => {
            const keys = [];
            keys.push({
                frame: 0,
                value: 0
            });
            keys.push({
                frame: 10,
                value: 1
            });

            for (const key in symbolMeshes) {
                for (let i=0; i<symbolMeshes[key].length; i++) {
                    if (key == 'basket') {
                        if (symbolMeshes[key][i].name == '3.Darangshi cave') {
                            createGUI(scene, symbolMeshes[key][i], key, '다랑쉬굴', '100px', new BABYLON.Vector3(-8.2, 0.4, -4.1), new BABYLON.Vector3(0.7, 0.1, 0.3), 6);
                        }
                    } else if (key == 'tree') {
                        if (symbolMeshes[key][i].name == '2.Nakseon-dong') {
                            createGUI(scene, symbolMeshes[key][i], key, '낙선동 4·3성', '150px', new BABYLON.Vector3(-4.8, 0.4, -5.6), new BABYLON.Vector3(1.1, 0.1, 0.4), 5);
                        }
                    } else if (key == 'boat') {
                        if (symbolMeshes[key][i].name == '1.Bukchon Neobunsoongi') {
                            createGUI(scene, symbolMeshes[key][i], key, '북촌 너븐숭이', '150px', new BABYLON.Vector3(-5, 0.1, -7), new BABYLON.Vector3(0.3,0.2,1.1), 4);
                        }
                    } else if (key == 'well') {
                        if (symbolMeshes[key][i].name == '5.The lost') {
                            createGUI(scene, symbolMeshes[key][i], key, '곤을동 잃어버린 마을', '200px', new BABYLON.Vector3(-1.4, 0.3, -5.8), new BABYLON.Vector3(1.6, 0.1, 0.4), 2);
                        }
                    } else if (key == 'water') {
                        if (symbolMeshes[key][i].name == '4.Former distillery') {
                            createGUI(scene, symbolMeshes[key][i], key, '주정공장 옛터', '150px', new BABYLON.Vector3(1.8, 0.3, -5.3), new BABYLON.Vector3(1.1, 0.1, 0.4), 3);
                        }
                    } else if (key == 'flower') {
                        if (symbolMeshes[key][i].name == '6.BSM') {
                            createGUI(scene, symbolMeshes[key][i], key, '백조일손지지·섯알오름·만뱅듸', '250px', new BABYLON.Vector3(7.7, 0.2, 3), new BABYLON.Vector3(1.1, 0.1, 0.8), 1);
                        }
                    } else if (key == 'lobby') {
                        if (symbolMeshes[key][i].name.endsWith('0')) {
                            createGUI(scene, symbolMeshes[key][i], key, '메모리얼파크', '250px', new BABYLON.Vector3(4, 0.7, 1), new BABYLON.Vector3(2,1,1.5), 7);
                        }
                    }
                }
            }
            
        };

        const createCamera = (scene) => {
            const camera = new BABYLON.ArcRotateCamera("camera",  0, 0, 10, new BABYLON.Vector3(8, 5, 2), scene);
            camera.attachControl(jejuCanvas.value, true);
            camera.alpha = 1.7520;
            camera.beta = 0.4384;
            camera.radius = 22;
            camera.lowerRadiusLimit = 5;
            camera.upperBetaLimit = 1.27;
            camera.upperRadiusLimit = 90;
            camera.wheelPrecision = 400.0;
            camera.angularSensibilityX = 2000;
            camera.angularSensibilityY = 2000;
            camera.useAutoRotationBehavior = false;
            camera.lockedTarget = new BABYLON.Vector3(3., 0, 0);
            camera.inputs.remove(camera.inputs.attached.keyboard);
        };

        onUnmounted(()=>{
            sceneManager.closeScene();
            sceneManager.closeEngine();
        });

        return {
            isModalOpen,
            contentIndex,
            jejuCanvas,
            readyToSceneTrigger,
            zoomOutCamera,
            mainMenu,
            zoom
        }
    },
    methods: {
        closeModal() {
            this.isModalOpen = false;

            this.mainMenu.inActive();
        },
        selectedContent(index) {
            if(index === 8) {
                this.closeModal();
                return;
            }
            const scene = SceneManager.getInstance().scene;
            this.contentIndex = index;
            this.isModalOpen = true;
            if (this.contentIndex == 1) {
                this.zoom(scene.activeCamera, scene.getMeshByName('백조일손지지·섯알오름·만뱅듸'), 'flower');
            } else if (this.contentIndex == 2) {
                this.zoom(scene.activeCamera, scene.getMeshByName('곤을동 잃어버린 마을'), 'well');
            } else if (this.contentIndex == 3) {
                this.zoom(scene.activeCamera, scene.getMeshByName('주정공장 옛터'), 'water');
            } else if (this.contentIndex == 4) {
                this.zoom(scene.activeCamera, scene.getMeshByName('북촌 너븐숭이'), 'boat');
            } else if (this.contentIndex == 5) {
                this.zoom(scene.activeCamera, scene.getMeshByName('낙선동 4·3성'), 'tree');
            } else if (this.contentIndex == 6) {
                this.zoom(scene.activeCamera, scene.getMeshByName('다랑쉬굴'), 'basket');
            } else if (this.contentIndex == 7) {
                this.zoom(scene.activeCamera, scene.getMeshByName('메모리얼파크'), 'lobby');
            }
        }
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#jejuCanvas{
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    display: block;
    overflow: hidden;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 2s;
    z-index: -1;
}

</style>








